import { useCallbackOnKeys, useDownloadImage, useScreen } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useExpandViewImage } from "@/stores"
import { AnimatePresence, motion } from "framer-motion"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FaSearchMinus, FaSearchPlus, FaUndo } from "react-icons/fa"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import LoadingLogo from "../LoadingLogo"
import classNames from "classnames"
import client from "@/api/client"
import { DownloadIcon1, XIcon } from "../shared/icons"
import IconButton from "../IconButton"

const FullViewImageScreen = () => {
  const [expandViewImage, setExpandViewImage] = useExpandViewImage(state => [
    state.expandViewImage,
    state.setExpandViewImage,
  ])
  const downloadImage = useDownloadImage(
    expandViewImage?.url!,
    expandViewImage?.name ?? expandViewImage?.id ?? expandViewImage?.url ?? "image",
    () => {},
  )

  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const { width, height } = useScreen()
  const alt = "example"
  const zoomFactor = 5

  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0)
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0)

  const [rotate, setRotate] = useState<number>(0)
  const ref = React.useRef<HTMLDivElement>(null)

  const onClose = useCallback(() => {
    setExpandViewImage(null)
  }, [setExpandViewImage])

  const imageScale = useMemo(() => {
    if (width === 0 || height === 0 || imageNaturalWidth === 0 || imageNaturalHeight === 0) return 0
    const scale = Math.min(width / imageNaturalWidth, height / imageNaturalHeight)
    return Math.min(Math.max(scale, 1), 1)
  }, [width, height, imageNaturalWidth, imageNaturalHeight])

  const handleImageOnLoad = (image: HTMLImageElement) => {
    setImageNaturalWidth(image.naturalWidth)
    setImageNaturalHeight(image.naturalHeight)
  }

  useCallbackOnKeys(
    [
      {
        key: "Escape",
      },
    ],
    () => {
      onClose()
    },
    !!expandViewImage?.url,
  )

  useEffect(() => {
    if (expandViewImage?.url) {
      setImageLoaded(false)

      const image = new Image()
      image.onload = () => handleImageOnLoad(image)
      image.src = expandViewImage.url

      googleAnalytics.event({
        action: "click",
        category: "full_view_image",
        label: `image_${expandViewImage.url}`,
        value: 1,
      })
      return
    }
    setImageLoaded(false)
  }, [expandViewImage?.url])

  useEffect(() => {
    if (!expandViewImage) return

    addEventListener("popstate", onClose)

    return () => {
      removeEventListener("popstate", onClose)
    }
  }, [expandViewImage])

  if (!expandViewImage) return null

  return (
    <AnimatePresence
      mode="wait"
      key={imageLoaded ? "loaded" : "loading"}
      onExitComplete={() => {
        setImageNaturalWidth(0)
        setImageNaturalHeight(0)
        setRotate(0)
      }}
    >
      <div
        onClick={onClose}
        className="fixed z-[51] flex flex-col w-full h-full overflow-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-blackAlpha-900 modal-content-child"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25, ease: "easeInOut" }}
          className="flex flex-col items-center justify-center flex-1 w-full"
        >
          <div
            className={classNames("absolute z-[1] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2", {
              hidden: imageLoaded,
            })}
          >
            <LoadingLogo />
          </div>
          <div
            style={{
              opacity: imageLoaded ? 1 : 0,
              width: "100%",
              height: "100%",
            }}
          >
            {imageScale > 0 && (
              <TransformWrapper
                key={`${width}x${height}x${expandViewImage.url}`}
                initialScale={imageScale}
                minScale={0.5}
                maxScale={imageScale * zoomFactor}
                centerOnInit
              >
                {({ zoomIn, zoomOut }) => (
                  <>
                    <div className="fixed z-10 flex flex-row space-x-2 -translate-x-1/2 bottom-4 left-1/2">
                      <IconButton
                        colorScheme="secondary"
                        onClick={e => {
                          e.stopPropagation()
                          zoomIn(0.25)
                        }}
                        className="p-2 transition-opacity duration-300 opacity-100 md:opacity-70 hover:opacity-100"
                      >
                        <FaSearchPlus size="0.8rem" />
                      </IconButton>
                      <IconButton
                        colorScheme="secondary"
                        onClick={e => {
                          e.stopPropagation()

                          zoomOut(0.25)
                        }}
                        className="p-2 transition-opacity duration-300  opacity-100 md:opacity-70 hover:opacity-100"
                      >
                        <FaSearchMinus size="0.8rem" />
                      </IconButton>
                      <IconButton
                        colorScheme="secondary"
                        onClick={e => {
                          e.stopPropagation()

                          setRotate(rotate - 90)
                        }}
                        className="p-2 transition-opacity duration-300  opacity-100 md:opacity-70 hover:opacity-100"
                      >
                        <FaUndo size="0.8rem" />
                      </IconButton>

                      <IconButton
                        colorScheme="secondary"
                        onClick={e => {
                          e.stopPropagation()

                          setRotate(rotate + 90)
                        }}
                        style={{
                          transform: "rotateY(180deg)",
                        }}
                        className="p-2 transition-opacity duration-300  opacity-100 md:opacity-70 hover:opacity-100"
                      >
                        <FaUndo size="0.8rem" />
                      </IconButton>
                      <IconButton
                        colorScheme="secondary"
                        onClick={async e => {
                          e.stopPropagation()

                          await downloadImage().finally(async () => {
                            try {
                              if (!expandViewImage.id) return
                              await client.api.imageControllerDownloadImage({
                                imageIds: [expandViewImage.id],
                              })
                            } catch (error) {}
                          })
                        }}
                        className="p-2 transition-opacity duration-300  opacity-100 md:opacity-70 hover:opacity-100"
                      >
                        <DownloadIcon1 width={16} height={16} />
                      </IconButton>
                      <IconButton
                        colorScheme="secondary"
                        onClick={e => {
                          e.stopPropagation()
                          onClose()
                        }}
                        className="p-2 transition-opacity duration-300  opacity-100 md:opacity-70 hover:opacity-100"
                      >
                        <XIcon width={16} height={16} />
                      </IconButton>
                    </div>
                    <TransformComponent
                      wrapperStyle={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        onClick={e => {
                          e.stopPropagation()
                        }}
                      >
                        <img
                          onLoad={() => setImageLoaded(true)}
                          style={{
                            transform: `rotate(${rotate}deg)`,
                            transition: "transform 0.25s",
                            maxHeight: "100vh",
                          }}
                          className="object-contain"
                          alt={alt}
                          src={expandViewImage.url}
                        />
                      </div>
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            )}
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  )
}

export default memo(FullViewImageScreen)
